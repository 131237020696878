import React from 'react'
import * as firebase from "firebase";
import 'firebase/database'

class Background extends React.Component{
    constructor(){
        super()
    }


    render() {
        return(
            <div>
                <img src={this.props.background} className="image" height="100%" width="100%" />
            </div>
        )
    }
}
export default Background