import React from 'react';
import logo from './logo.svg';
import './App.css';
import Background from "./Page/Background";
import Selector from "./Page/Selector";

import * as firebase from 'firebase'

var firebaseConfig = {
  apiKey: "AIzaSyDOVIbNqvVPQ2Gn283zVE9mBi_hjjDSvB4",
  authDomain: "background-4b6ca.firebaseapp.com",
  databaseURL: "https://background-4b6ca.firebaseio.com",
  projectId: "background-4b6ca",
  storageBucket: "background-4b6ca.appspot.com",
  messagingSenderId: "340570273155",
  appId: "1:340570273155:web:f0a7af34b20422eec4b2b6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class App extends React.Component{
  constructor(){
    super()
    this.state={
      screen:"pageSelector",
      background:""
    }
  }

  componentDidMount() {
    firebase.database().ref("background").on('value',(value)=>{
      this.setState({
        background:value.val()
      })
    })
  }

  render() {
    return(
        <div className="fullDiv flex">
        {this.state.screen==="pageSelector"&&
          <div className="fullDiv options">
            {["Background","Selector"].map((value)=>{
              return(
                  <button onClick={()=>{
                    this.setState({
                      screen:value
                    })
                  }}>{value}</button>
              )
            })}
          </div>}

          {this.state.screen==="Background" && <Background background={this.state.background} />}
          {this.state.screen==="Selector" && <Selector background={this.state.background} />}

        </div>
    )
  }
}

export default App;
