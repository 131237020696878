import React from 'react'
import * as firebase from "firebase";
import "firebase/storage"

class Selector extends React.Component{
    constructor(){
        super()
        this.state={
            images:[],
            links:[],
            background:"",
            link:""
        }
        this.setSrc=this.setSrc.bind(this)
        this.firebaseUpdate=this.firebaseUpdate.bind(this)
        this.upload=this.upload.bind(this)
        this.handleInputChange=this.handleInputChange.bind(this)
        this.addLink=this.addLink.bind(this)
    }

    addLink(event){
        firebase.database().ref("links").push(this.state.link)
        this.setState({link:""})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    firebaseUpdate(){
        var databaseStorage=firebase.database()
        var databaseRef=databaseStorage.ref("/images")
        var storageRef=firebase.storage()
        var listRef =storageRef.ref()
        listRef.listAll().then(async (value) => {
            var newList= await Promise.all(value.items.map(async value1 => {
                return await value1.getDownloadURL()
            }))
            databaseRef.set(newList)
        })
    }

    componentDidMount() {
        var databaseStorage=firebase.database()
        var databaseRef=databaseStorage.ref("/images")


        databaseRef.on('value',a => {
            var value=a.val()
            this.setState({images:value})
            }
        )
        databaseStorage.ref("background").on("value", a=>{
            var value=a.val()
            this.setState({background:value})
        })

        firebase.database().ref("links").on("value", a => {
            var vals=a.val()
            var lijst=[]
            if(vals!==null){
            lijst=Object.values(vals)
            }
            this.setState({links:lijst})
        })

        this.firebaseUpdate()
    }


    setSrc(name){
        firebase.database().ref("background").set(name)
    }

    upload(event){
        var uploadTask = firebase.storage().ref(event.target.files[0].name).put(event.target.files[0]);
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot)=>{
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, (error)=> {
            // Handle unsuccessful uploads
        }, ()=> {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                console.log('File available at', downloadURL);
                this.firebaseUpdate();
            });
        });
        event.target.value=""

    }

    render() {
        var images=this.state.images
        var links=this.state.links
        var lijst=images.concat(links)
        return(
            <div className="imageOptions fullDiv">
                {lijst.map((value)=>{
                    var number=250
                        return(<img onClick={()=>{this.setSrc(value)}} src={value} style={value===this.state.background ? {borderColor: "yellow"}:{borderColor:"black"}}  className="image option" width={number} height={(number*9)/16}/>)

                })
                }
                <div>
                    <input type="file" onChange={this.upload}/><br/>
                    <input type='url' value={this.state.link} name="link" onChange={this.handleInputChange}/>
                    <button onClick={this.addLink}>Submit</button>
                </div>

            </div>
        )
    }
}
export default Selector